export default {
    breakpoints: ['40em', '52em', '64em'],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    fonts: {
      body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      heading: 'inherit',
      monospace: 'Menlo, monospace',
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
    sizes: {
        container: 1000,
    },
    radii: [5, 15, 30, 100],
    fontWeights: {
      body: 400,
      heading: 700,
      bold: 700,
    },
    lineHeights: {
      body: 1.5,
      heading: 1.125,
    },
    colors: {
      text: '#171C31',
      background: '#fff',
      grey: '#A5A5B3',
      primary: '#FF6748',
      secondary: '#6D4DE4',
      muted: '#F6F8FC',
      green: 'limegreen',
    },
    shadows: [
      `0px 2px 18px 10px rgba(50,50,93,0.06)`,
      `0px 2px 5px 0px rgba(50,50,93,0.2)`,
    ],
    borders: [
      '1px solid #EBEBEB',
    ],
    text: {
      heading: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
      },
    },
    styles: {
      root: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'body',
      },
      h1: {
        variant: 'text.heading',
        fontSize: 3,
      },
      h2: {
        variant: 'text.heading',
        fontSize: 4,
      },
      h3: {
        variant: 'text.heading',
        fontSize: 3,
      },
      h4: {
        variant: 'text.heading',
        fontSize: 2,
      },
      h5: {
        variant: 'text.heading',
        fontSize: 1,
      },
      h6: {
        variant: 'text.heading',
        fontSize: 0,
      },
      a: {
        color: "primary",
        ":hover, :focus": {
          color: "secondary",
        },
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit',
        },
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit',
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
      },
    },
    buttons: {
      primary: {
        color: 'background',
        bg: 'primary',
        '&:hover': {
          bg: 'secondary',
        },
        '&:focus': {
          outline: 'none',
        },
        px: 4, py: 3, mt: 3,
        fontWeight: 'bold', textTransform: 'uppercase',
        border: 'none', borderRadius: 0,
        svg: {
          mb: '-2px', mr: 1,
        },
      },
      secondary: {
        color: 'background',
        bg: 'secondary',
      },
    },
    forms: {
      label: {
        fontSize: 1,
        fontWeight: 'bold',
        mt: 4, mb: 2,
      },
      input: {
        borderColor: 'gray',
        bg: 'background',
        '&:focus': {
          borderColor: 'secondary',
          boxShadow: 1,
          outline: 'none',
        },
      },
      select: {
        borderColor: 'gray',
        bg: 'background',
        '&:focus': {
          borderColor: 'secondary',
          boxShadow: 1,
          outline: 'none',
        },
      },
      textarea: {
        borderColor: 'gray',
        bg: 'background',
        '&:focus': {
          borderColor: 'secondary',
          boxShadow: 1,
          outline: 'none',
        },
      },
      slider: {
        bg: 'muted',
      },
    },
}